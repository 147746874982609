import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from './views/containers/DashBoard'

const SaccoBaseRouter = () =>(
     <div>
        <Route exact path='/' component={Dashboard}  />
    </div>
);

export default SaccoBaseRouter;


