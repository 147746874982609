import React from "react";
import axios from "axios";
import {
  Table,
  Input,
  Button,
  Collapse,
  Popover,
  Modal,
  Divider,
  Tabs,
  Spin,
  Card,
  Form,
  Select,
  InputNumber,
  Drawer,
  Descriptions,
  Popconfirm,
  message,
  DatePicker
} from "antd";
import Highlighter from "react-highlight-words";
import Icon from "@ant-design/icons";
import {
  SearchOutlined,
  EditOutlined,
  PrinterFilled,
  ShoppingCartOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  FundViewOutlined,
  PlusOutlined,
  WalletFilled,
  DollarCircleFilled,
  FileTextFilled,
  PrinterOutlined,
  DownloadOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import * as serverconfig from "../serverconn";
import CurrencyFormat from "react-currency-format";
import { Col, Row } from "reactstrap";
import PhoneInput from "react-phone-number-input";
import {
  UserOutlined,
  LockOutlined,
  LoadingOutlined,
  MailOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ReactToPrint from "react-to-print";
import * as reactstrp from "reactstrap";
import moment from "moment";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");
const { Search } = Input;

const { Panel } = Collapse;
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const dateFormat = "DD/MM/YYYY";

function callback(key) {
  console.log(key);
}
var token = "";
var bizuserid = "";
var instance_id=''

const { RangePicker } = DatePicker;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class ReportToPrint extends React.Component {
  state = {
    companyprofile: {},
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });
  }

 

//calculate items total
calculate_list_total=(list_tems)=>{
  var total=0
  list_tems.map((item)=>{
    total+=Number(item.total)
  })
  return total;
}


  render() {
    return (
      <div style={{ padding: 20 }}>
        
        <div style={{display:'flex', flexDirection:'',justifyContent: 'space-between', margin:3}}>
           
           <div style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly'}}>
           <img
              height="240"
              width="200"
              alt="Logo"
              src={this.state.companyprofile.company_logo}
            />

            <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
             
              <h6 style={{ fontWeight: "bolder" }}>
                Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              </h6>
              <h5 style={{ fontWeight: "bolder" }}>
                Email: {this.state.companyprofile.company_email}
              </h5>

            </div>

           </div>

           <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>

           <h1
              style={{
                display: "flex",
                justifyContent: 'flex-end',
                alignItems: "center",
                fontWeight: "bolder",
              }}
            >
              PROFORMA 
            </h1>        

           
            <h2
              style={{
                display: "flex",
                justifyContent: 'flex-end',
                alignItems: "center",
                fontWeight: "bolder",
              }}
            >
              INVOICE 
            </h2>
           </div>

        </div>  
        <Divider></Divider>

        <div style={{display:'flex', flexDirection:'',justifyContent: 'space-between', margin:3}}>
          <div>
           <h4 style={{ fontWeight: "bolder" }} >
            {" INVOICE TO: " + this.props.selected_record.clientname}
          </h4>
          <h4 style={{ fontWeight: "bolder" }} >
            {this.props.selected_record.clientaddress}
          </h4>
          </div>

          <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
          <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
          {"INVOICE NO: " + this.props.selected_record.invoice_no}
          </h4>
          <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
            {" DATE: " + this.props.selected_record.date}
          </h4>
        

          <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
            {"DETAILS: " + this.props.selected_record.details}
          </h4>

            {
              this.props.selected_record.invoice_type==="FreeText Invoice"?
              null:
              <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
              {"PERIOD: " + this.props.selected_record.period_range}
                </h4>

            }
         

          </div>

        </div>


        {
          this.props.selected_record.invoice_type==="DeliveryFees Invoice"?

          <reactstrp.Table bordered>
            <thead>
              <tr>
              <th>NO</th>
              <th>DATE</th>
              <th>ID</th>
              <th>PHONE</th>
              <th>AREA</th>
              <th>STATUS</th>
              <th>DELIVERY FEE</th>
              </tr>
            </thead>
            <tbody>
              {JSON.parse(this.props.selected_record.items_list).map((item) => (
                <tr>
                  <td>{item.item_no}</td>
                  <td>{item.date}</td>
                  <td>{item.ordernumber}</td>
                  <td>{item.client_phone}</td>
                  <td>{item.area}</td>
                  <td>{item.order_status}</td>

                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                </tr>
              ))}

              <tr>
              <td style={{ fontWeight: "bolder" }}>TOTAL ( {JSON.parse(this.props.selected_record.items_list).length} )</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>

              <td className="align-right" style={{ fontWeight: "bolder" }}>
               UGX&nbsp;
                {
                  <CurrencyFormat
                    value={this.calculate_list_total(JSON.parse(this.props.selected_record.items_list))}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </td>
            </tr>

            </tbody>
          </reactstrp.Table>
          :null}

         {
          this.props.selected_record.invoice_type==="PerOrderServiceFees Invoice"?
          <reactstrp.Table bordered>
            <thead>
              <tr>
              <th>NO</th>
                <th>DATE</th>
                <th>ID</th>
                <th>PHONE</th>
                <th>SERVICE FEE</th>
                <th>AREA</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {JSON.parse(this.props.selected_record.items_list).map((item) => (
                <tr>
                  <td>{item.item_no}</td>
                  <td>{item.date}</td>
                  <td>{item.ordernumber}</td>
                  <td>{item.client_phone}</td>
                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                  <td>{item.area}</td>
                  <td>{item.order_status}</td>
                </tr>
              ))}

              <tr>
                 <td style={{ fontWeight: "bolder" }}>TOTAL ( {JSON.parse(this.props.selected_record.items_list).length} )</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="align-right" style={{ fontWeight: "bolder" }}>
                  UGX&nbsp;
                {
                  <CurrencyFormat
                    value={this.calculate_list_total(JSON.parse(this.props.selected_record.items_list))}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
                </td>
                <td></td>
                <td></td>
            </tr>

            </tbody>
          </reactstrp.Table>
          :null
          }


        {
          this.props.selected_record.invoice_type==="OnlineOrdersCommission Invoice"?
          <reactstrp.Table bordered>
            <thead>
              <tr>
              <th>NO</th>
              <th>DATE</th>
              <th>ID</th>
              <th>PHONE</th>
              <th>COMMISSION</th>
              <th>AREA</th>
              <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {JSON.parse(this.props.selected_record.items_list).map((item) => (
                <tr>
                  <td>{item.item_no}</td>
                  <td>{item.date}</td>
                  <td>{item.ordernumber}</td>
                  <td>{item.client_phone}</td>
                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                  <td>{item.area}</td>
                  <td>{item.order_status}</td>
                </tr>
              ))}

              <tr>
              <td style={{ fontWeight: "bolder" }}>Total ( {JSON.parse(this.props.selected_record.items_list).length} )</td>
              <td></td>
              <td></td>
              <td></td>
              <td className="align-right" style={{ fontWeight: "bolder" }}>
                {
                  <CurrencyFormat
                    value={this.calculate_list_total(JSON.parse(this.props.selected_record.items_list))}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </td>
              <td></td>
              <td></td>
            </tr>

            </tbody>
          </reactstrp.Table>
          :null}



        {
          this.props.selected_record.invoice_type==="Subscriptions Invoice" ?
            <reactstrp.Table bordered>
            <thead>
              <tr>
                <th>NO</th>
                <th>DESCRIPTION</th>
                <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>

            {JSON.parse(this.props.selected_record.items_list).map((item) => (
                <tr>
                  <td>{item.key}</td>
                  <td>{item.item_name}</td>
                  <td className="align-right">
                    {
                      <CurrencyFormat
                        value={Number(item.total)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    }
                  </td>
                </tr>
              ))}
              <tr>
              <td style={{ fontWeight: "bolder" }}>TOTAL ( {JSON.parse(this.props.selected_record.items_list).length} )</td>
                <td></td>
                <td className="align-right" style={{ fontWeight: "bolder" }}>
                  {
                  <CurrencyFormat
                  value={this.calculate_list_total(JSON.parse(this.props.selected_record.items_list))}
                  displayType={"text"}
                  thousandSeparator={true}
                  />    
                    }
                </td>
              </tr>
            </tbody>
          </reactstrp.Table>
          :
          null
          } 


          {
            this.props.selected_record.invoice_type==="FreeText Invoice" ?
              <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>DESCRIPTION</th>
                  <th>AMOUNT</th>
                </tr>
              </thead>
              <tbody>

              {JSON.parse(this.props.selected_record.items_list).map((item) => (
                  <tr>
                    <td>{item.key}</td>
                    <td>{item.item_name}</td>
                    <td className="align-right">
                      {
                        <CurrencyFormat
                          value={Number(item.total)}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }
                    </td>
                  </tr>
                ))}
                <tr>
                <td style={{ fontWeight: "bolder" }}>TOTAL ( {JSON.parse(this.props.selected_record.items_list).length} )</td>
                  <td></td>
                  <td className="align-right" style={{ fontWeight: "bolder" }}>
                    {
                    <CurrencyFormat
                    value={this.calculate_list_total(JSON.parse(this.props.selected_record.items_list))}
                    displayType={"text"}
                    thousandSeparator={true}
                    />    
                      }
                  </td>
                </tr>
              </tbody>
            </reactstrp.Table>
            :
            null
            } 


          <Divider></Divider>
          <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-end'}}>
            <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }}>MOTOR CITY IMPORTS LTD</h4>
            <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }}>BANK ACCOUNT: STANBIC - 9030009507611 </h4>
            <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
            MTN: 570379 
            </h4>
            <h4 style={{ fontWeight: "bolder",alignSelf:'flex-end' }} >
            AIRTEL: 4360801 
            </h4>

          </div>


      </div>
    );
  }
}



class InvoiceList extends React.Component {
  formRef = React.createRef();
  state = {
    searchText: "",
    searchedColumn: "",
    unpaid_invoices: [],
    paid_invoices: [],
    unpaid_invoices_placeholder: [],
    paid_invoices_placeholder: [],

    invoice_payments: [],
    datarequested: true,
    selected_record:{},
    dateone:'',
    datetwo:'',
    status:'UNPAID',
    userrights:{},

  };


  componentDidMount() {
    this.compo_loaded()
  }


  compo_loaded=()=>{
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }


    if (localStorage.getItem("bizuserid")) {
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      bizuserid = "";
    }



    let form_data = new FormData();
    form_data.append('restaurant_id', instance_id);

    axios
      .post(
        serverconfig.backendserverurl + `/customqueries/getunpaidinvoices_byrestaurant`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        this.setState({ unpaid_invoices: JSON.parse(res.data.unpaidinvoices) });
        this.setState({ paid_invoices: JSON.parse(res.data.paidinvoices) });

        this.setState({ unpaid_invoices_placeholder: JSON.parse(res.data.unpaidinvoices) });
        this.setState({ paid_invoices_placeholder: JSON.parse(res.data.paidinvoices) });


        this.setState({ datarequested: false });
      });


      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
         console.log("data: "+res.data[0] )
          this.setState({
            userrights:res.data[0]
          })
      })


      //set dates
      this.setState({dateone:this.formatDate(this.getFirstDayLastWeek())})
      this.setState({datetwo:this.formatDate(this.getLastDayThisWeek())})




  }




  getFirstDayLastWeek = () => {
    const today = new Date();
    const firstDayLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
    return firstDayLastWeek;
  };


  getLastDayThisWeek = () => {
    const today = new Date();
    const lastDayThisWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - today.getDay()));
    return lastDayThisWeek;

  };


   formatDate = date => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  //calculate items total
  calculate_list_total=(list_tems)=>{
    var total=0
    list_tems.map((item)=>{
      total+=Number(item.total)
    })
    return total;
  }


  calculate_INVOICE_TOTALS_tot=(list_tems)=>{
    var total=0
    list_tems.map((item)=>{
      total+=Number(item.totalAmount)
    })
    return total;
  }

  calculate_INVOICE_TOTALS_paid=(list_tems)=>{
    var total=0
    list_tems.map((item)=>{
      total+=Number(item.totalPaid)
    })
    return total;
  }

  calculate_INVOICE_TOTALS_bal=(list_tems)=>{
    var total=0
    list_tems.map((item)=>{
      total+=Number(item.dueAmount)
    })
    return total;
  }



  render() {
    const columns = [
      {
        title: "No",
        dataIndex: "item_no",
        key: "id",
      },
      {
        title: "Invoice Type",
        dataIndex: "invoice_type",
        key: "id",
        ...this.getColumnSearchProps("invoice_type"),
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "id",
        ...this.getColumnSearchProps("date"),
      },

      {
        title: "Range",
        dataIndex: "period_range",
        key: "id",
      },
      {
        title: "Details",
        dataIndex: "details",
        key: "id",
      },

      {
        title: "InvoiceNo",
        dataIndex: "invoice_no",
        key: "id",
        ...this.getColumnSearchProps("invoice_no"),
      },

      {
        title: "Total Amount",
        dataIndex: "totalAmount",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },

      {
        title: "Paid Amount",
        dataIndex: "totalPaid",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },
      {
        title: "Balance",
        dataIndex: "dueAmount",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      }

    ];

    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large">
            <div className="content" />
          </Spin>
        </div>
      );
    } else {
      return (
        <div>

         

            {/**invoice print */}
            <div style={{ display: "none" }}>
                &nbsp;&nbsp;
                <ReportToPrint
                  selected_record={this.state.selected_record}
                  ref={(el) => (this.componentRef = el)}
                />
            </div>



          <Tabs defaultActiveKey="3" 
            onChange={(val)=>{
              console.log(val)
              if (val==="1"){
                this.setState({status:"UNPAID"})
              }else if (val==="2"){
                this.setState({status:"PAID"})
              }
             }}>



              {/**upPaid invoices */}

            <TabPane tab="Unpaid Invoices" key="1">


            <div style={{overflowX:'auto'}}>


             <Form layout="inline">
                  <FormItem label="Filter by Type">
                    <Search
                      placeholder="Filter by Type"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val === "" || val === undefined || val === null) {
                          this.setState({
                            unpaid_invoices: this.state.unpaid_invoices_placeholder,
                          });
                        }
  
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.unpaid_invoices.filter(
                            (request) => {
                              return String(request.invoice_type)
                                .toLowerCase()
                                .includes(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              unpaid_invoices: this.state.unpaid_invoices_placeholder,
                            });
                          } else {
                            this.setState({ unpaid_invoices: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            unpaid_invoices: this.state.unpaid_invoices_placeholder,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>


                </Form>
                <br></br>



            <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>INVOICES : {this.state.unpaid_invoices.length}</th>
  
                      <th>
                        TOTAL AMOUNT:{"  UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculate_INVOICE_TOTALS_tot(this.state.unpaid_invoices)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>

                      <th>
                        TOTAL PAID :{" UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculate_INVOICE_TOTALS_paid(this.state.unpaid_invoices)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>

                      <th>
                        TOTAL BALANCE:{" UGX "}
                        {
                          <CurrencyFormat
                            value={
                              this.calculate_INVOICE_TOTALS_bal(this.state.unpaid_invoices)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>
                      
                    </tr>
                  </thead>
                </reactstrp.Table>

                </div>
                <br></br>
         
          
              <Table
                columns={columns}
                pagination={{ showQuickJumper: true, showSizeChanger: true }}
                dataSource={this.state.unpaid_invoices}
                scroll={{ x: 1000 }}
                bordered
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div
                        style={{
                          margin: 0,
                        }}
                      >
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              type="primary"
                              shape="round"
                              icon={<PrinterOutlined />}
                              size={this.state.size}
                            >
                              Print Invoice
                            </Button>
                          )}
                          content={() => this.componentRef}
                        />

                        <br></br>
                        <br></br>
                        <Tabs>
                          <TabPane tab="Invoice Items" key="01">
                         
                          {
                            record.invoice_type==="DeliveryFees Invoice"?

                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                <th>NO</th>
                                <th>DATE</th>
                                <th>ID</th>
                                <th>PHONE</th>
                                <th>AREA</th>
                                <th>STATUS</th>
                                <th>DELIVERY FEE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.item_no}</td>
                                    <td>{item.date}</td>
                                    <td>{item.ordernumber}</td>
                                    <td>{item.client_phone}</td>
                                    <td>{item.area}</td>
                                    <td>{item.order_status}</td>

                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                  </tr>
                                ))}

                               <tr>
                                <td style={{ fontWeight: "bolder" }}>Total ( {JSON.parse(record.items_list).length} )</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td className="align-right" style={{ fontWeight: "bolder" }}>
                                  {
                                    <CurrencyFormat
                                      value={this.calculate_list_total(JSON.parse(record.items_list))}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  }
                                </td>
                              </tr>


                              </tbody>
                            </reactstrp.Table>
                            :null}


                          {
                            record.invoice_type==="PerOrderServiceFees Invoice"?
                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                <th>NO</th>
                                <th>DATE</th>
                                <th>ID</th>
                                <th>PHONE</th>
                                <th>SERVICE FEE</th>
                                <th>AREA</th>
                                <th>STATUS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.item_no}</td>
                                    <td>{item.date}</td>
                                    <td>{item.ordernumber}</td>
                                    <td>{item.client_phone}</td>
                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                    <td>{item.area}</td>
                                    <td>{item.order_status}</td>
                                  </tr>
                                ))}

                               <tr>
                                <td style={{ fontWeight: "bolder" }}>Total ( {JSON.parse(record.items_list).length} )</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="align-right" style={{ fontWeight: "bolder" }}>
                                 {
                                    <CurrencyFormat
                                      value={this.calculate_list_total(JSON.parse(record.items_list))}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  }
                                </td>
                                <td></td>
                                <td></td>
                              </tr>

                              </tbody>
                            </reactstrp.Table>
                            :null}


                          {
                            record.invoice_type==="OnlineOrdersCommission Invoice"?
                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                <th>NO</th>
                                <th>DATE</th>
                                <th>ID</th>
                                <th>PHONE</th>
                                <th>COMMISSION</th>
                                <th>AREA</th>
                                <th>STATUS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.item_no}</td>
                                    <td>{item.date}</td>
                                    <td>{item.ordernumber}</td>
                                    <td>{item.client_phone}</td>
                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                    <td>{item.area}</td>
                                    <td>{item.order_status}</td>
                                  </tr>
                                ))}

                               <tr>
                                <td style={{ fontWeight: "bolder" }}>Total ( {JSON.parse(record.items_list).length} )</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="align-right" style={{ fontWeight: "bolder" }}>
                                 {
                                    <CurrencyFormat
                                      value={this.calculate_list_total(JSON.parse(record.items_list))}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  }
                                </td>
                                <td></td>
                                <td></td>
                              </tr>

                              </tbody>
                            </reactstrp.Table>
                            :null}


                          {
                            record.invoice_type==="Subscriptions Invoice" ?
                              <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>NO</th>
                                  <th>DESCRIPTION</th>
                                  <th>AMOUNT</th>
                                </tr>
                              </thead>
                              <tbody>

                              {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.key}</td>
                                    <td>{item.item_name}</td>
                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                <td style={{ fontWeight: "bolder" }}>TOTAL ( {JSON.parse(record.items_list).length} )</td>
                                  <td></td>
                                  <td className="align-right" style={{ fontWeight: "bolder" }}>
                                    {
                                    <CurrencyFormat
                                    value={this.calculate_list_total(JSON.parse(record.items_list))}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    />    
                                     }
                                  </td>
                                </tr>
                              </tbody>
                            </reactstrp.Table>
                            :
                            null
                            }  


                            {
                            record.invoice_type==="FreeText Invoice" ?
                              <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>NO</th>
                                  <th>DESCRIPTION</th>
                                  <th>AMOUNT</th>
                                </tr>
                              </thead>
                              <tbody>

                              {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.key}</td>
                                    <td>{item.item_name}</td>
                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                <td style={{ fontWeight: "bolder" }}>TOTAL ( {JSON.parse(record.items_list).length} )</td>
                                  <td></td>
                                  <td className="align-right" style={{ fontWeight: "bolder" }}>
                                    {
                                    <CurrencyFormat
                                    value={this.calculate_list_total(JSON.parse(record.items_list))}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    />    
                                     }
                                  </td>
                                </tr>
                              </tbody>
                            </reactstrp.Table>
                            :
                            null
                            }  


                          </TabPane>

                      

                          <TabPane tab="Invoice Payments" key="02">
                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Amount</th>
                                  <th>Payment mode</th>
                                 
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.invoice_payments.map((item) => (
                                  <tr>
                                    <td>{item.date}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.payment_account}</td>


                                  </tr>
                                ))}
                              </tbody>
                            </reactstrp.Table>
                          </TabPane>

                        </Tabs>
                      </div>
                    );
                  },
                  rowExpandable: (record) => true,
                  onExpand: (condition, record) => {
                    this.setState({selected_record:record})

                    axios
                      .get(
                        serverconfig.backendserverurl +
                          `/api/invoice_payments/?invoice=${record.id}`
                      )
                      .then((res) => {
                        this.setState({
                          invoice_payments: res.data,
                        });
                      });
                  },

                  expandIcon: (props) => {
                    const { expanded, onExpand } = props;
    
                     return (<span style={{ color: primarycolor.primarycolor, fontSize: '30px' }}
                     onClick={(e) => {
                      onExpand(props.record, e);
                    }}
                     
                     ><PlusCircleOutlined /></span>)
                    
                  }
                }}
                size='small'               
              />
              
            </TabPane>


                {/**Paid invoices */}
            <TabPane tab="Paid Invoices" key="2">
            <div style={{overflowX:'auto'}}>


            <Form layout="inline">
                  <FormItem label="Filter by Type">
                    <Search
                      placeholder="Filter by Type"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val === "" || val === undefined || val === null) {
                          this.setState({
                            paid_invoices: this.state.paid_invoices_placeholder,
                          });
                        }
  
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.paid_invoices.filter(
                            (request) => {
                              return String(request.invoice_type)
                                .toLowerCase()
                                .includes(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              paid_invoices: this.state.paid_invoices_placeholder,
                            });
                          } else {
                            this.setState({ paid_invoices: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            paid_invoices: this.state.paid_invoices_placeholder,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>

                </Form>
            <br></br>

            <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>INVOICES : {this.state.paid_invoices.length}</th>
  
                      <th>
                        TOTAL AMOUNT:{"  UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculate_INVOICE_TOTALS_tot(this.state.paid_invoices)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>

                      <th>
                        TOTAL PAID :{" UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculate_INVOICE_TOTALS_paid(this.state.paid_invoices)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>

                      <th>
                        TOTAL BALANCE:{" UGX "}
                        {
                          <CurrencyFormat
                            value={
                              this.calculate_INVOICE_TOTALS_bal(this.state.paid_invoices)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </th>
                      
                    </tr>
                  </thead>
                </reactstrp.Table>
                </div>
                <br></br>


              <Table
                columns={columns}
                pagination={{ showQuickJumper: true, showSizeChanger: true }}
                dataSource={this.state.paid_invoices}
                scroll={{ x: 1000 }}
                bordered
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div
                        style={{
                          margin: 0,
                        }}
                      >


                        <ReactToPrint
                          trigger={() => (
                            <Button
                              type="primary"
                              shape="round"
                              icon={<PrinterOutlined />}
                              size={this.state.size}
                            >
                              Print Invoice
                            </Button>
                          )}
                          content={() => this.componentRef}
                        />


                        <br></br>
                        <br></br>
                        <Tabs>
                          <TabPane tab="Invoice Items" key="01">
                         
                          {
                            record.invoice_type==="DeliveryFees Invoice"?

                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                <th>NO</th>
                                <th>DATE</th>
                                <th>ID</th>
                                <th>PHONE</th>
                                <th>AREA</th>
                                <th>STATUS</th>
                                <th>DELIVERY FEE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.item_no}</td>
                                    <td>{item.date}</td>
                                    <td>{item.ordernumber}</td>
                                    <td>{item.client_phone}</td>
                                    <td>{item.area}</td>
                                    <td>{item.order_status}</td>

                                    <td >
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                  </tr>
                                ))}

                              <tr>
                                <td style={{ fontWeight: "bolder" }}>Total ( {JSON.parse(record.items_list).length} )</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td className="align-right" style={{ fontWeight: "bolder" }}>
                                  {
                                    <CurrencyFormat
                                      value={this.calculate_list_total(JSON.parse(record.items_list))}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  }
                                </td>
                              </tr>

                              </tbody>
                            </reactstrp.Table>
                            :null}


                          {
                            record.invoice_type==="PerOrderServiceFees Invoice"?
                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                <th>NO</th>
                                <th>DATE</th>
                                <th>ID</th>
                                <th>PHONE</th>
                                <th>SERVICE FEE</th>
                                <th>AREA</th>
                                <th>STATUS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.item_no}</td>
                                    <td>{item.date}</td>
                                    <td>{item.ordernumber}</td>
                                    <td>{item.client_phone}</td>
                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                    <td>{item.area}</td>
                                    <td>{item.order_status}</td>
                                  </tr>
                                ))}

                               <tr>
                                <td style={{ fontWeight: "bolder" }}>Total ( {JSON.parse(record.items_list).length} )</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="align-right" style={{ fontWeight: "bolder" }}>
                                 {
                                    <CurrencyFormat
                                      value={this.calculate_list_total(JSON.parse(record.items_list))}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  }
                                </td>
                                <td></td>
                                <td></td>
                              </tr>

                              </tbody>
                            </reactstrp.Table>
                            :null}


                          {
                            record.invoice_type==="OnlineOrdersCommission Invoice"?
                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                <th>NO</th>
                                <th>DATE</th>
                                <th>ID</th>
                                <th>PHONE</th>
                                <th>COMMISSION</th>
                                <th>AREA</th>
                                <th>STATUS</th>
                                </tr>
                              </thead>
                              <tbody>
                                {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.item_no}</td>
                                    <td>{item.date}</td>
                                    <td>{item.ordernumber}</td>
                                    <td>{item.client_phone}</td>
                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                    <td>{item.area}</td>
                                    <td>{item.order_status}</td>
                                  </tr>
                                ))}

                               <tr>
                                <td style={{ fontWeight: "bolder" }}>Total ( {JSON.parse(record.items_list).length} )</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="align-right" style={{ fontWeight: "bolder" }}>
                                 {
                                    <CurrencyFormat
                                      value={this.calculate_list_total(JSON.parse(record.items_list))}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  }
                                </td>
                                <td></td>
                                <td></td>
                              </tr>

                              </tbody>
                            </reactstrp.Table>
                            :null}


                          {
                            record.invoice_type==="Subscriptions Invoice" ?
                              <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>NO</th>
                                  <th>DESCRIPTION</th>
                                  <th>AMOUNT</th>
                                </tr>
                              </thead>
                              <tbody>

                              {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.key}</td>
                                    <td>{item.item_name}</td>
                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                <td style={{ fontWeight: "bolder" }}>TOTAL ( {JSON.parse(record.items_list).length} )</td>
                                  <td></td>
                                  <td className="align-right" style={{ fontWeight: "bolder" }}>
                                    {
                                    <CurrencyFormat
                                    value={this.calculate_list_total(JSON.parse(record.items_list))}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    />    
                                     }
                                  </td>
                                </tr>
                              </tbody>
                            </reactstrp.Table>
                            :
                            null
                            }  



                          {
                            record.invoice_type==="FreeText Invoice" ?
                              <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>NO</th>
                                  <th>DESCRIPTION</th>
                                  <th>AMOUNT</th>
                                </tr>
                              </thead>
                              <tbody>

                              {JSON.parse(record.items_list).map((item) => (
                                  <tr>
                                    <td>{item.key}</td>
                                    <td>{item.item_name}</td>
                                    <td className="align-right">
                                      {
                                        <CurrencyFormat
                                          value={Number(item.total)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      }
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                <td style={{ fontWeight: "bolder" }}>TOTAL ( {JSON.parse(record.items_list).length} )</td>
                                  <td></td>
                                  <td className="align-right" style={{ fontWeight: "bolder" }}>
                                    {
                                    <CurrencyFormat
                                    value={this.calculate_list_total(JSON.parse(record.items_list))}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    />    
                                     }
                                  </td>
                                </tr>
                              </tbody>
                            </reactstrp.Table>
                            :
                            null
                            }  


                          </TabPane>

                          <TabPane tab="Invoice payments" key="02">
                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Amount</th>
                                  <th>Payment mode</th>
                               
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.invoice_payments.map((item) => (
                                  <tr>
                                    <td>{item.date}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.payment_account}</td>
                                 
                                  </tr>
                                ))}
                              </tbody>
                            </reactstrp.Table>
                          </TabPane>


                        </Tabs>
                      </div>
                    );
                  },
                  rowExpandable: (record) => true,
                  onExpand: (condition, record) => {
                    this.setState({selected_record:record})

                    axios
                      .get(
                        serverconfig.backendserverurl +
                          `/api/invoice_payments/?invoice=${record.id}`
                      )
                      .then((res) => {
                        this.setState({
                          invoice_payments: res.data,
                        });
                      });
                  },
                }}
                size='small'

              />
            </TabPane>

          </Tabs>
        </div>
      );
    }
  }
}

export default InvoiceList;
