import React from 'react';
import { Form, Input, Button,Spin,Select,Result,Divider,DatePicker,Image,Switch,Card, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from "../serversocketconfig";

import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import ReconnectingWebSocket from "reconnecting-websocket";

var CryptoJS = require("crypto-js");
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var username=''
var instance_id = "";

const { Option } = Select;

const { TextArea } = Input;
const t_time="HH:mm:ss"
var riderrefresh_socket=new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/riderrefresh_socket/');


class InHouseOrderForm extends React.Component {

  state = {
    client_name:'' ,  
    client_phone:'',
    datarequested:true,
    active:false,
    image:null,
    image_file:'',
    date_created:moment().format(dateFormat).toString(),
    date_of_expiry:'',
    order_total:0,
    area:'',
    restaurant_profile:{},
    hub_zones:[],
    delivery_price:0,
    hub_riders:[],
    selected_rider:'',
    exclusive_riders:[],
    support_riders:[],
    datasubmittedsuccessfully:false,
    areas:[],

    secondary_hub_riders:[],
    notes:'',


  }

  componentDidMount(){

    //reset notes
      //observer the socket changes now
      riderrefresh_socket.onopen = () => {
      console.log("onopen for riderrefresh_socket on inhouseform");
    // connection opened
    };

    riderrefresh_socket.onmessage = async (e) => {
      this.component_load()
    };

    this.component_load()

  }

  //component load
  component_load=()=>{
    //this.setState({datarequested:true})


    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("username")){
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
      username=''
    }

    axios.defaults.client_nameers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/restaurants/${instance_id}`)
    .then(res => {
      this.setState({restaurant_profile:res.data}) 
      var hub=res.data.hub
      var sec_hub=res.data.secondry_hub

      //now get hub zones and prices
      axios.get(serverconfig.backendserverurl+`/api/hub_zones/?hub=${hub}`)
      .then(res => {
        this.setState({hub_zones:res.data})
        ///console.log("zones: "+JSON.stringify(res.data[0]))
      })

      //get hub riders
      axios.get(serverconfig.backendserverurl+`/api/riders/?assigned_hub=${hub}&available=${true}`)
      .then(res => {
        this.setState({hub_riders:res.data})
        ///console.log("zones: "+JSON.stringify(res.data[0]))
      })

      if (sec_hub!="null" && sec_hub!=null  && sec_hub!=''){
        axios.get(serverconfig.backendserverurl+`/api/riders/?assigned_hub=${sec_hub}&available=${true}`)
        .then(res => {
          this.setState({secondary_hub_riders:res.data})
          ///console.log("zones: "+JSON.stringify(res.data[0]))
        })
      }
     

      //get exclusive rider
    })


    //get exclusive rider
    axios.get(serverconfig.backendserverurl+`/api/riders/?exclusive_restaurant=${instance_id}&available=${true}`)
    .then(res => {
      console.log("Exclusive riders: "+JSON.stringify(res.data))
      this.setState({exclusive_riders:res.data})
    })


    axios.get(serverconfig.backendserverurl+`/api/areas/`)
    .then(res => {
        this.setState({
          areas:res.data
        })

    })

    //get support riders
    axios.get(serverconfig.backendserverurl+`/api/riders/?support_rider=${true}`)
    .then(res => {
      this.setState({support_riders:res.data})

      this.setState({datarequested:false})

    })

   // this.setState({datarequested:false})
    this.setState({datasubmittedsuccessfully:false})

  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this .state.area===""){
      message.error("Please enter area")
    }else if (this.state.delivery_price<=0){
      message.error("Delivery charge not calculated ")
    }else{

      //////////////////////////////////
      //check if rider is asigned already

      let form_data__1 = new FormData();
      form_data__1.append('rider_id', this.state.selected_rider);
  
      axios.post(serverconfig.backendserverurl+'/customqueries/check_ifriderassigned', form_data__1, {
        client_nameers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {

    //check if assined
    if (res.data.message==="Assigned"){
      message.error("Rider assigned. Select another rider ")
      this.component_load()

    }else{

      //now send request


          this.setState({datarequested:true})
          let form_data = new FormData();
          form_data.append('client_name', this.state.client_name);
          form_data.append('client_phone', this.state.client_phone);
          form_data.append('restaurant',instance_id);
          form_data.append('area',this.state.area);
          form_data.append('delivery_price',this.state.delivery_price);
          form_data.append('date',this.state.date_created);
          form_data.append('username',username);
          form_data.append('order_total',this.state.order_total);
          form_data.append('selected_rider',this.state.selected_rider);
          form_data.append('placing_time', moment().format(t_time).toString());
          form_data.append('mode_of_payment',"cash");
          form_data.append('notes',this.state.notes);

          axios.post(serverconfig.backendserverurl+'/customqueries/createinhouseorder', form_data, {
            client_nameers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res => {
          this.setState({datarequested:false})
          //this.setState({datasubmittedsuccessfully:true})
            this.component_load()
          message.info("Order created")

          })
        .catch(error => console.log(error))
        //end sending order


    }



  

      })
      .catch(error => console.log(error))
      //end check




   

}


 }

    //get the delivery zone
   //check if area already added
   isareaAlreadyExists = (area, list) => {
    return list.some((item) => item === area);
  };


  
  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title="Action successful"
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  this.component_load()
                }}>Finish</Button>,
              ]}
              />

              </Card>
          </div>
        )    

      }else{

      return (
        <div>
          <h3>NEW PHONE ORDER</h3>

          <Row>

            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Card>
            <Form
                onFinish={(event) => this.handleFormSubmit(event)}
            >
             <FormItem label="CUSTOMER NAME">
                <Input name="client_name" 
                placeholder="CUSTOMER NAME."
                value={this.state.client_name} 
                onChange={(val)=>{this.setState({client_name:val.target.value})}} />
              </FormItem>


              <FormItem label="CLIENT PHONE">
                <Input 
                name="client_phone" 
                placeholder="CLIENT PHONE."
                value={this.state.client_phone} 
                onChange={(val)=>{this.setState({client_phone:val.target.value})}}
                type='number'
                />
              </FormItem>

              <FormItem label="AMOUNT">
                <Input 
                name="Total" 
                placeholder="AMOUNT."
                value={this.state.order_total} 
                onChange={(val)=>{
                  this.setState({order_total:val.target.value})
                                
                }}
                type='number'
                />
              </FormItem>


              <FormItem 
                  label="DELIVERY AREA"
                  name="area"
                  rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}
                  >
                <Select 
                placeholder="DELIVERY AREA" 
                value={this.state.area} 
                onChange={(val)=>{
                  console.log(val)
                  this.setState({area:val})
                  this.setState({delivery_price:0})

                  //get delivery fees
                   this.state.hub_zones.map((zone)=>{
                    var zone_list=JSON.parse(zone.zone_list)
                    var price=zone.price
                    if (this.isareaAlreadyExists(val,zone_list)){
                      this.setState({delivery_price:price})

                    }else{
                      console.log("No in zone")
                    }

                  })   

                
                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                >
                   {this.state.areas.map(
                    (cat)=>(
                      <Option value={cat.name}>{cat.name}</Option>
                    ))}
                </Select>
            </FormItem>


              {
                  this.state.exclusive_riders.length>0?
                  <FormItem 
                  label="RIDER"
                
                  >
                    <Select 
                    placeholder="RIDER" 
                    value={this.state.selected_rider} 
                    onChange={(val)=>{
                      this.setState({selected_rider:val})
                      
                    }} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}    
                    >
                       {this.state.exclusive_riders.map(
                        (cat)=>(
                          <Option value={cat.id}>{cat.name} {cat.phone}</Option>
                        ))}
                    </Select>
                </FormItem>
                :

                    <FormItem 
                    label="RIDER"
                    name="rider"
                    rules={[
                        {
                          required: true,
                          message: 'Please select',
                        },
                      ]}
                    >
                  <Select 
                  placeholder="RIDER" 
                  value={this.state.selected_rider} 
                  onChange={(val)=>{
                    this.setState({selected_rider:val})
                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}    
                  >
                      {this.state.support_riders.map(
                      (cat)=>(
                        <Option value={cat.id}>{cat.name} {cat.phone}</Option>
                      ))}
                  </Select>
                  </FormItem>
                  
                  }

                  <h3 style={{fontWeight:'bolder',justifyContent:'center',display:'flex',alignSelf:'center'}}> FEE: {this.state.delivery_price} UGX</h3>


            <Divider></Divider>

            <FormItem  label={<p>Notes &nbsp;</p>}>
              <Input 
              name="note" 
              placeholder=""
              value={this.state.notes} 
              onChange={(val)=>{this.setState({notes:val.target.value})}}
              />
            </FormItem>


              <FormItem>
                <Button  type="primary" htmlType="submit">Create order</Button>
              </FormItem>
              </Form>

        </Card>
        </Col>
        </Row>

        </div>
      );
    }
    }

  }

}


export default InHouseOrderForm;



