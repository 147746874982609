import React from "react";
import axios from "axios";
import {
  Table,
  Input,
  Button,
  Collapse,
  Popover,
  Popconfirm,
  Form,
  message,
  Tabs,
  Spin,
  Modal,
  Select,
  Result,
  DatePicker,
  Tooltip,
  Card
} from "antd";
import Highlighter from "react-highlight-words";
import Icon from "@ant-design/icons";
import {
  SearchOutlined,
  DeleteOutlined,
  FundViewOutlined,
  DownloadOutlined,
  PrinterOutlined,
  LoadingOutlined,
  EditFilled,
  CloseCircleOutlined,
  PlayCircleOutlined,
  CheckCircleFilled,
  PlusCircleOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import * as serverconfig from "../serverconn";
import CurrencyFormat from "react-currency-format";
import * as reactstrp from "reactstrap";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Col, Row } from "reactstrap";
import OrderUpdateForm from "./OrderUpdateForm";
import { MdMotorcycle } from "react-icons/md";
import * as primarycolor from '../primarycolor'
import '../../App.css'; // Create this file for custom styles

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem = Form.Item;
var token = "";
var instance_id=''
const { Search } = Input;
const { Option } = Select;
const { TextArea } = Input;

const rowClassName = (record) => {
  return record.order_type === "Online Order" ? 'highlighted-row' : '';
};


const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class ReportToPrint extends React.Component {
  state = {
    companyprofile: {},
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });
  }

  //clactulate totla of items
  calculateTotalofItems = () => {
    var total = 0;

    JSON.parse(this.props.selected_record.item_details).map((item) => {
      total += Number(item.total);
    });

    return total;
  };

  render() {
    return (
      <div style={{ padding: 20 }}>
        <Row>
          <Col xs="2" sm="2" lg="2">
            <img
              height="150"
              width="150"
              alt="Logo"
              src={this.state.companyprofile.company_logo}
            />
          </Col>
          <Col xs="6" sm="6" lg="6" >
            <h6 style={{ fontWeight: "bolder",justifyContent:'center' }}>
              Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
            </h6>
            <h5 style={{ fontWeight: "bolder",justifyContent:'center' }}>
              Email: {this.state.companyprofile.company_email}
            </h5>
          </Col>
        </Row>

        <reactstrp.Table bordered>
          <thead>
            <tr>
              <th>
                <h4 style={{ fontWeight: "bolder" }}>
                  DATE: {this.props.selected_record.date}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  ORDER: {this.props.selected_record.ordernumber}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  ITEMS: {this.props.selected_record.items_total}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  STATUS: {this.props.selected_record.order_status}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  RESTAURANT: {this.props.selected_record.restaurantname}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  RIDER: {this.props.selected_record.assigned_ridername}
                </h4>
              </th>

              <th>
                <h4 style={{ fontWeight: "bolder" }}>
                  CUSTOMER NAME: {this.props.selected_record.client_name}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                CUSTOMER PHONE: {this.props.selected_record.client_phone}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                CUSTOMER EMAIL: {this.props.selected_record.client_email}
                </h4>
                <h4 style={{ fontWeight: "bolder" }}>
                  DELIVERY AREA: {this.props.selected_record.area}
                </h4>
              </th>

              <th>
                <h4 style={{ fontWeight: "bolder" }}>
                  DELIVERY FEES:
                  <CurrencyFormat
                    value={this.props.selected_record.delivery_charges}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h4>

         

                <h4 style={{ fontWeight: "bolder" }}>
                  ITEMS COST:
                  <CurrencyFormat
                    value={this.props.selected_record.order_total}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h4>

                <h4 style={{ fontWeight: "bolder" }}>
                  TOTAL:
                  <CurrencyFormat
                    value={
                      Number(this.props.selected_record.order_total) +
                      Number(this.props.selected_record.delivery_charges)
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h4>

                <h4 style={{ fontWeight: "bolder" }}>
                  TOTAL PAID:
                  <CurrencyFormat
                    value={this.props.selected_record.total_paid}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </h4>
              </th>
            </tr>
          </thead>
        </reactstrp.Table>
        <br></br>

        <reactstrp.Table bordered>
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Amount</th>
              <th>Total</th>
              <th>Choices</th>
              <th>Extras</th>
              <th>Packaging Fees</th>
            </tr>
          </thead>
          <tbody>
          {JSON.parse(this.props.selected_record.item_details).map((item) => (
              <tr>
                <td>{item.key}</td>
                <td>{item.item}</td>
                <td>{item.quantity}</td>
                <td>{item.price}</td>
                <td>{item.total}</td>
                <th>
                {
                  JSON.parse(item.choice_list).length>0?
                  <span>
                  <span>
                  {
                    JSON.parse(item.choice_list).map((x_itm)=>{
                      return ""+(x_itm.item)+", "
                    })       
                  }
                  </span>
                  </span>
                  :
                  null
                }



                </th>
                <th>
                {
                JSON.parse(item.extra_list).length>0?
                <span>
                <span>
                {
                  JSON.parse(item.extra_list).map((x_itm)=>{
                    return "( "+(x_itm.item)+","+(x_itm.price)+" ), "
                  })       
                }
                </span>
                </span>
                :
                null
              }

               </th>
               <th>{item.packaging_fees}</th>

              </tr>
            ))}
          </tbody>
        </reactstrp.Table>

      </div>
    );
  }
}

class ReportToPrint2 extends React.Component {
  state = {
    companyprofile: {},
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/app_profile/${1}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });
      });

  }



  //clactulate totla of items
  calculateTotalofItems_deliveryfees = () => {
    var total = 0;

    this.props.clientorders.map((item) => {
      total += Number(item.delivery_charges);
    });

    return total;
  };

  calculateTotalItems_cost = () => {
    var total = 0;

    this.props.clientorders.map((item) => {
      total += Number(item.order_total);
    });

    return total;
  };

  calculateTotalofItems_Paid = () => {
    var total = 0;
    this.props.clientorders.map((item) => {
      total += Number(item.total_paid);
    });

    return total;
  };



  render() {
    return (
      <div style={{padding:20}}>

        <Row>
          <Col xs="2" sm="2" lg="2">
            <img
              height="150"
              width="150"
              alt="Logo"
              src={this.state.companyprofile.company_logo}
            />
          </Col>
          <Col xs="6" sm="6" lg="6">
           
            <h6 style={{ fontWeight: "bolder" }}>
              Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
            </h6>
            <h5 style={{ fontWeight: "bolder" }}>
              Email: {this.state.companyprofile.company_email}
            </h5>
          </Col>
        </Row>

        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bolder",
          }}
        >
          CLIENT ORDERS FROM {this.props.dateone} TO {this.props.datetwo}
        </h2>


        <reactstrp.Table bordered>
          <thead>

            <tr>
              <th>Order ID</th>
              <th>Order Type</th>
              <th>Status</th>

              <th>Date (DD/MM/YYYY)</th>
              <th>Assigned Rider</th>

              <th>Delivery area</th>
              <th>Delivery Fees</th>
              
              <th>Customer Name</th>
              <th>Customer Phone</th>
              <th>Customer Email</th>
              <th>Total</th>
              <th>Total Paid</th>
            </tr>
          </thead>
          <tbody>
            {this.props.clientorders.map((item) => (
              <tr>
                <td>{item.ordernumber}</td>
                <td>{item.order_type}</td>
                <td>{item.order_status}</td>
                <td>{item.date}</td>
                      
                <td>{item.assigned_ridername}</td>
                <td>{item.area}</td>

                <td>
                  {
                    <CurrencyFormat
                      value={Number(item.delivery_charges)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>

                <td>{item.client_name}</td>
                <td>{item.client_phone}</td>
                <td>{item.client_email}</td>

                
                <td>
                  {
                    <CurrencyFormat
                      value={Number(item.order_total)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>
                <td>
                  {
                    <CurrencyFormat
                      value={
                        Number(item.total_paid)
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                </td>

              </tr>
            ))}

            <tr>
              <td></td>
              <td></td>
              <td></td>

              <td></td>
              <td></td>
              <td></td>

              <th>
                {
                  <CurrencyFormat
                    value={this.calculateTotalofItems_deliveryfees()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </th>
              <td></td>
              <td></td>
              <td></td>


              <th>
                {
                  <CurrencyFormat
                    value={this.calculateTotalItems_cost()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </th>
           

              <th>
                {
                  <CurrencyFormat
                    value={this.calculateTotalofItems_Paid()}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
              </th>

            </tr>
          </tbody>
        </reactstrp.Table>
      </div>
    );
  }
}

class OrdersList extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    clientorders: [],
    datarequested: true,
    selected_record: {},
    accounts: [],
    selected_agent: "",

    dateone: moment().format(dateFormat).toString(),
    datetwo: moment().format(dateFormat).toString(),
    order_status: "All",


    order_cancel_visible:false,
    cancelling_reason:'',
    selected_record:{},
    datasubmittedsuccessfully:false,
    
    order_assigned_rider:'',
    order_area:'',

    order_reconciliation_visible:false,
    paidAmount:0,
    reconciliation_status:'',



  };

  componentDidMount(){

    this.component_load()
      
  }


   getYesterdayDate=()=> {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const formattedYesterday = `${yesterday.getDate()}/${yesterday.getMonth() + 1}/${yesterday.getFullYear()}`;
    return formattedYesterday;
  }
  



  component_load=()=>{

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    
    var yest_date=this.getYesterdayDate()

    let form_data = new FormData();
    form_data.append("dateone", yest_date);
    form_data.append("datetwo", this.state.datetwo);
    form_data.append("order_status", this.state.order_status);
    form_data.append("restaurant_id",instance_id );

    if (this.state.dateone === "" || this.state.datetwo === "") {
      message.error("Please dates are missing");
    } else {
      this.setState({ datarequested: true });
      this.setState({ clientorders: [] });
      this.setState({ clientorders_placeholders: [] });

      //Now submit sale data to database
      axios
        .post(
          serverconfig.backendserverurl +
            "/customqueries/getclientorder_deliveriesreport_forrestaurant_all",
          form_data,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.setState({ datarequested: false });
          this.setState({ clientorders: JSON.parse(res.data.report) });
          this.setState({
            clientorders_placeholders: JSON.parse(res.data.report),
          });
        })
        .catch((error) => console.log(error));
    }

    //get agents
    axios
      .get(serverconfig.backendserverurl + `/api/accounts/?is_online=${true}`)
      .then((res) => {
        this.setState({
          accounts: res.data,
        });
      });

      this.setState({datasubmittedsuccessfully:false})
  }



  //clactulate totla of items
  calculateTotalofItems_deliveryfees = () => {
    var total = 0;

    this.state.clientorders.map((item) => {
      total += Number(item.delivery_charges);
    });

    return total;
  };

  calculateTotalItems_cost = () => {
    var total = 0;

    this.state.clientorders.map((item) => {
      total += Number(item.order_total);
    });

    return total;
  };

  calculateTotalofItems_Paid = () => {
    var total = 0;
    this.state.clientorders.map((item) => {
      total += Number(item.total_paid);
    });

    return total;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Order ID",
        dataIndex: "ordernumber",
        key: "id",
        ...this.getColumnSearchProps("ordernumber"),

        render: (text, record) => (
          <div>
             <p>
            {text}
            </p>

             {
              record.order_status === "Cancelled"?
              <p style={{fontWeight:'bold'}}>
              {"CANCELLED FOR: "+record.cancelling_reason}
              </p>
              :
              null
            }
 
          </div>
        )  


      },
      {
        title: "Order Type",
        dataIndex: "order_type",
        key: "id",
        ...this.getColumnSearchProps("order_type"),
      },
   
      {
        title: "Status",
        dataIndex: "order_status",
        key: "id",
        ...this.getColumnSearchProps("order_status"),
        render: (text, record) => (
          <div>
          <p
            style={{
              color:
                record.order_status === "pending"
                  ? "orange"
                  : record.order_status === "Cancelled"
                  ? "red"
                  : "green",
            }}
          >
            {text}
          </p>

          <p style={{display:'flex',flexDirection:'row',}}>

          { 
             record.order_status==="Accepted" && record.order_type==="Online Order" && record.time_of_orderready_restaurant===null?
              <Tooltip title="Click to confirm order ready" placement="top">
              <Popconfirm title="You are confirming order readiness. Are you sure?" 
                  onConfirm={
                  () => {
                    const t_time="HH:mm:ss"
                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('order_id',record.id);
                    form_data.append('dispatch_time', moment().format(t_time).toString());
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/confirm_orderready_by_restaurant', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => 
                      
                      {
                      this.setState({datarequested:false})
                      message.info(res.data.message)
                      //this.setState({datasubmittedsuccessfully:true})

                      this.component_load()
      

                    })
                    .catch(error => console.log(error))
                  }
                }>

                 <PlayCircleOutlined style={{color:'green',fontSize: '30px',margin:2}} />

               </Popconfirm>
              </Tooltip>
              :
              null
           }


          { 
             record.order_status==="Pending" && record.order_type==="Phone Order"?
              <Tooltip title="Click to confirm pickup " placement="top">
               <Popconfirm title="You are confirming pickup. Are you sure?" 
                 onConfirm={
                  () => {
                    const t_time="HH:mm:ss"
                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('order_id',record.id);
                    form_data.append('dispatch_time', moment().format(t_time).toString());
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/confirm_orderpickup_by_restaurant', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      message.info(res.data.message)

                      this.setState({datarequested:false})
                     // this.setState({datasubmittedsuccessfully:true})

                     this.component_load()

      
                    })
                    .catch(error => console.log(error))
    
                  }
                }>

                 <MdMotorcycle 
               
               style={{color:'green',fontSize: '30px',margin:2}} />

               </Popconfirm>
              </Tooltip>
              :
              null
            }



        { 
             record.order_status==="Accepted" && record.order_type==="Online Order"?
              <Tooltip title="Click to confirm pickup " placement="top">
               <Popconfirm title="You are confirming pickup. Are you sure?" 
                 onConfirm={
                  () => {
                    const t_time="HH:mm:ss"
                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('order_id',record.id);
                    form_data.append('dispatch_time', moment().format(t_time).toString());
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/confirm_orderpickup_by_restaurant', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      message.info(res.data.message)

                      this.setState({datarequested:false})
                     // this.setState({datasubmittedsuccessfully:true})

                     this.component_load()

      
                    })
                    .catch(error => console.log(error))
    
                  }
                }>

                 <MdMotorcycle 
               
               style={{color:'green',fontSize: '30px',margin:2}} />

               </Popconfirm>
              </Tooltip>
              :
              null
            }


            {
             record.order_status==="Pending"?
             <Tooltip title="Click to cancel order " placement="top">
               <CloseCircleOutlined onClick={()=>{
                  this.setState({selected_record:record})
                  this.setState({order_cancel_visible:true})

               }}  style={{color:'#FF6347',fontSize: '30px',margin:2}} />
             </Tooltip>

              :
              null
            }


          { 
             record.order_status==="Delivering"?
             <Tooltip title="Click to reconcile order " placement="top">
               <CheckCircleFilled onClick={()=>{
                  this.setState({selected_record:record})
                  this.setState({order_reconciliation_visible:true})

               }}  style={{color:'green',fontSize: '30px',margin:2}} />
             </Tooltip>
              :
              null
            }


          </p>


          </div>


        ),
      },
      {
        title: "Date (DD/MM/YYYY)",
        dataIndex: "date",
        key: "id",
        ...this.getColumnSearchProps("date"),
      },

   
      {
        title: "Assigned Rider",
        dataIndex: "assigned_ridername",
        key: "id",
        ...this.getColumnSearchProps("assigned_ridername"),
      },


      {
        title: "Delivery Area",
        dataIndex: "area",
        key: "id",
      },
      {
        title: "Delivery Fees ",
        dataIndex: "delivery_charges",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },

      {
        title: "Customer Name",
        dataIndex: "client_name",
        key: "id",
      },

      {
        title: "Customer Phone",
        dataIndex: "client_phone",
        key: "id",
      },

      {
        title: "Customer Email",
        dataIndex: "client_email",
        key: "id",
      },


      {
        title: "Total",
        dataIndex: "order_total",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },

      {
        title: "Total Paid",
        dataIndex: "total_paid",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },
    ];

    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
          </Spin>
        </div>
      );
    } else {


      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title="Action successful"
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  this.component_load()
                }}>Finish</Button>,
              ]}
              />
              </Card>
          </div>
        )

      }else{

        return (
          <div>
            <Collapse defaultActiveKey={["1"]} onChange={this.callback}>
              <Panel header="ALL ORDERS" key="1">

                  <div style={{overflowX:'auto'}}>

                  <div style={{ display: "none" }}>
                  &nbsp;&nbsp;
                  <ReportToPrint2
                    dateone={this.state.dateone}
                    datetwo={this.state.datetwo}
                    clientorders={this.state.clientorders}
                    ref={(el) => (this.componentRef_2 = el)}
                  />
                </div>
  
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <ReactToPrint
                    trigger={() => (
                      <Button
                        type="primary"
                        shape="round"
                        icon={<PrinterOutlined />}
                        size={this.state.size}
                      >
                        Print
                      </Button>
                    )}
                    content={() => this.componentRef_2}
                  />
                  &nbsp;&nbsp;
                  <ExcelFile
                    filename={
                      "eats.biz -Orders From " +
                      this.state.dateone +
                      " To " +
                      this.state.datetwo
                    }
                    element={
                      <Button type="primary" icon={<DownloadOutlined />}>
                        Download Excel{" "}
                      </Button>
                    }
                  >

                    <ExcelSheet
                      data={this.state.clientorders}
                      name="Orders"
                    >
                      <ExcelColumn label="Order ID" value="ordernumber" />
                      <ExcelColumn label="Order Type" value="order_type" />
                      <ExcelColumn label="Order Status" value="order_status" />
                      <ExcelColumn label="Date (DD/MM/YYYY)" value="date" />
                     
                      <ExcelColumn
                        label="Assigned Rider"
                        value={"assigned_ridername"}
                      />
                      <ExcelColumn label="Delivery Area" value={"area"} />
                      <ExcelColumn label="Delivery Fees" value="delivery_charges" />
                      <ExcelColumn
                        label="Customer Name"
                        value="client_name"
                      />
                      <ExcelColumn
                        label="Customer Phone"
                        value="client_phone"
                      />
                      <ExcelColumn label="Customer Email" value="client_email" />
                      <ExcelColumn label="Total" value="order_total" />
                      <ExcelColumn label="Total Paid" value="total_paid" />
                    </ExcelSheet>
                  </ExcelFile>

                </div>
  
                <br></br>
                <br></br>

                <div style={{overflowX:'auto'}}>
                <Form layout="inline">
                  <FormItem label="Order Status">
                    <Select
                      placeholder="Order Status"
                      style={{ width: 200 }}
                      value={this.state.order_status}
                      onChange={(val) => {
                        this.setState({ order_status: val });
                      }}
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value={"All"}>All </Option>
                      <Option value={"Delivered"}>Delivered</Option>
                      <Option value={"Cancelled"}>Cancelled</Option>
                      <Option value={"Delivering"}>Delivering</Option>
                    </Select>
                  </FormItem>
  
                  <FormItem label="Date Range">
                    <RangePicker
                      onChange={(date, dateString) => {
                        this.setState({ dateone: dateString[0] });
                        this.setState({ datetwo: dateString[1] });
                      }}
                      format={dateFormat}
                      placeholder={['Start Date', 'End Date']}

                    />
                  </FormItem>
  
                  <FormItem>
                    <Button
                      onClick={() => {
                        let form_data = new FormData();
                        form_data.append("dateone", this.state.dateone);
                        form_data.append("datetwo", this.state.datetwo);
                        form_data.append("order_status", this.state.order_status);
                        form_data.append("restaurant_id",instance_id );

                        if (
                          this.state.dateone === "" ||
                          this.state.datetwo === ""
                        ) {
                          message.error("Please dates are missing");
                        } else {
                          this.setState({ datarequested: true });
                          this.setState({ clientorders: [] });
                          this.setState({ clientorders_placeholders: [] });
  
                          //Now submit sale data to database
                          axios
                            .post(
                              serverconfig.backendserverurl +
                                "/customqueries/getclientorder_deliveriesreport_forrestaurant_all",
                              form_data,
                              {
                                headers: {
                                  "content-type": "multipart/form-data",
                                },
                              }
                            )
                            .then((res) => {

                              this.setState({ datarequested: false });
                              this.setState({
                                clientorders: JSON.parse(res.data.report),
                              });
                              this.setState({
                                clientorders_placeholders: JSON.parse(
                                  res.data.report
                                ),
                              });



                            })
                            .catch((error) => console.log(error));
                        }
                      }}
                      type="primary"
                      htmlType="button"
                    >
                      Search
                    </Button>
                  </FormItem>
                </Form>
  
                <br></br>
  
                <Form layout="inline">
                  <FormItem label="Filter by Rider">
                    <Search
                      placeholder="Filter by Rider"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.clientorders.filter(
                            (request) => {
                              return String(request.assigned_ridername)
                                .toLowerCase()
                                .startsWith(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              clientorders: this.state.clientorders_placeholders,
                            });
                          } else {
                            this.setState({ clientorders: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>
  
             
                  <FormItem label="Filter by OrderType">
                    <Search
                      placeholder="Filter by OrderType"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val === "" || val === undefined || val === null) {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
  
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.clientorders.filter(
                            (request) => {
                              return String(request.order_type)
                                .toLowerCase()
                                .includes(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              clientorders: this.state.clientorders_placeholders,
                            });
                          } else {
                            this.setState({ clientorders: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>



                  <FormItem label="Filter by Delivery Area">
                    <Search
                      placeholder="Filter by Delivery Area"
                      onChange={(value) => {
                        var val = value.target.value;
                        console.log(val);
                        if (val === "" || val === undefined || val === null) {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
  
                        if (val !== "" || val !== undefined) {
                          //set one is by NAME
                          const results_1 = this.state.clientorders.filter(
                            (request) => {
                              return String(request.area)
                                .toLowerCase()
                                .includes(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            }
                          );
  
                          if (results_1.length === 0) {
                            this.setState({
                              clientorders: this.state.clientorders_placeholders,
                            });
                          } else {
                            this.setState({ clientorders: results_1 });
                            console.log(results_1);
                          }
                        } else {
                          this.setState({
                            clientorders: this.state.clientorders_placeholders,
                          });
                        }
                      }}
                      style={{
                        width: 200,
                        margin: 5,
                      }}
                    />
                  </FormItem>


                </Form>

                </div>
  



                <div style={{overflowX:'auto'}}>
                <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <td>ORDERS : {this.state.clientorders.length}</td>
  
                      <td>
                        TOTAL DELIVERY:{"  UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculateTotalofItems_deliveryfees()}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </td>
                      <td>
                        ITEMS COST :{" UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculateTotalItems_cost()}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </td>
                      <td>
                        TOTAL :{" UGX "}
                        {
                          <CurrencyFormat
                            value={
                              Number(this.calculateTotalItems_cost()) +
                              Number(this.calculateTotalofItems_deliveryfees())
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </td>
                      <td>
                        TOTAL PAID :{" UGX "}
                        {
                          <CurrencyFormat
                            value={this.calculateTotalofItems_Paid()}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </td>
                    </tr>
                  </thead>
                </reactstrp.Table>
                </div>

                <br></br>
  
                <Table
                  columns={columns}
                  scroll={{ x: 1000 }}
                  pagination={{ showQuickJumper: true, showSizeChanger: true }}
                  dataSource={this.state.clientorders}
                  bordered
                  expandable={{
                    expandedRowRender: (record) => {
                      return (
                        <div
                          style={{
                            margin: 0,
                          }}
                        >
                          <div style={{ display: "none" }}>
                            &nbsp;&nbsp;
                            <ReportToPrint
                              selected_record={record}
                              ref={(el) => (this.componentRef = el)}
                            />
                          </div>
  
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                type="primary"
                                shape="round"
                                icon={<PrinterOutlined />}
                                size={this.state.size}
                              >
                                Print Order
                              </Button>
                            )}
                            content={() => this.componentRef}
                          />
  
                          <br></br>
                          <br></br>
                          <Tabs>

                            {
                              record.assigned_rider===""?
                              <TabPane tab="Order Update" key={'03'}>
                              <OrderUpdateForm 
                                order_obj={record}
                                restaurant_id={record.restaurant}
                                onRefresh={this.component_load}  
  
                              />
  
                            </TabPane>
                            :
                            null
                            }
                           


                            <TabPane tab="Items" key="01">

                              <reactstrp.Table bordered>
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                    <th>Total</th>
                                    <th>Choices</th>
                                    <th>Extras</th>
                                    <th>Packaging fees</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {JSON.parse(record.item_details).map((item) => (
                                    <tr>
                                      <td>{item.key}</td>
                                      <td>{item.item}</td>
                                      <td>{item.quantity}</td>
                                      <td>{item.price}</td>
                                      <td>{item.total}</td>
                                      <th>
                                      {
                                        JSON.parse(item.choice_list).length>0?
                                        <span>
                                        <span>
                                        {
                                          JSON.parse(item.choice_list).map((x_itm)=>{
                                            return ""+(x_itm.item)+", "
                                          })       
                                        }
                                        </span>
                                        </span>
                                        :
                                        null
                                      }


                                      </th>
                                      <th>
                                      {
                                      JSON.parse(item.extra_list).length>0?
                                      <span>
                                      <span>
                                      {
                                        JSON.parse(item.extra_list).map((x_itm)=>{
                                          return "( "+(x_itm.item)+","+(x_itm.price)+" ), "
                                        })       
                                      }
                                      </span>
                                      </span>
                                      :
                                      null
                                    }

                                    </th>
                                    <th>{item.packaging_fees}</th>

                                    </tr>
                                  ))}
                                </tbody>
                              </reactstrp.Table>

                            </TabPane>

                            <TabPane tab="Time stamps" key="02">
                              <p>Time Of Order Placement By Client: {record.time_of_orderplacing_customer}</p>
                              <p>Time Of Order Acceptance By Restaurant: {record.time_of_orderacceptance_restaurant}</p>
  
                              <p>Time Of Rider Dispatch : {record.time_of_riderdispatch_restaurant}</p>
                              <p>Time Of Dispatch Accepted : {record.time_of_dispatchaccepted_rider}</p>
  
                              <p>Time Of Order Ready : {record.time_of_orderready_restaurant}</p>
                              <p>Time Of Order Picked : {record.time_of_orderpicked_rider}</p>
                              <p>Time Of Order Delivered : {record.time_of_orderdelivered_rider}</p>
                              <p>Time Of Reconciliation : {record.time_of_orderreconciled_restaurant}</p>
  
                            </TabPane>
                            
                          </Tabs>
                        </div>
                      );
                    },
                    rowExpandable: (record) => true,
                    onExpand: (condition, record) => {},
                    expandIcon: (props) => {
                      const { expanded, onExpand } = props;
      
                       return (<span style={{ color: primarycolor.primarycolor, fontSize: '30px' }}
                       onClick={(e) => {
                        onExpand(props.record, e);
                      }}
                       
                       ><PlusCircleOutlined /></span>)
                      
                    }


                  }}

                  rowClassName={rowClassName}

                />

                </div>

              </Panel>
            </Collapse>
  

            <Modal   
            visible={this.state.order_cancel_visible}
            title="Cancel Order"
            onCancel={()=>{
              this.setState({order_cancel_visible:false})
  
            }}
        
            footer={[
              <Button key="back" onClick={()=>{
                this.setState({order_cancel_visible:false})
  
              }}>
                Cancel
              </Button>,
              <Button key="back" type="primary" 
                onClick={()=>{
  
                  if (this.state.cancelling_reason===""){
                    message.error("Please enter a reason")
  
                  }else{
                    this.setState({datarequested:true})
  
                    let form_data = new FormData();
                    form_data.append('order_id',this.state.selected_record.id);
                    form_data.append('cancelling_reason',this.state.cancelling_reason);
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/cancelorder_byrestaurant', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      this.setState({datarequested:false})
                      //this.setState({datasubmittedsuccessfully:true})
                       this.component_load() 

                    })
                    .catch(error => console.log(error))
              
                    this.setState({order_cancel_visible:false})
                  }
  
              }}>
                Confirm
              </Button>
  
              ]}
              >
  
          <FormItem label="Reason For Cancelling">
                <TextArea
                      placeholder="Reason ."
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      value={this.state.cancelling_reason}
                      onChange={(val)=>{this.setState({cancelling_reason:val.target.value})}}
                    />
          </FormItem>
  
          </Modal> 




          <Modal   
            visible={this.state.order_reconciliation_visible}
            title="Order Reconciliation Form"
            onCancel={()=>{
              this.setState({order_reconciliation_visible:false})
            }}
        
            footer={[
              <Button key="back" onClick={()=>{
                this.setState({order_reconciliation_visible:false})
  
              }}>
                Cancel
              </Button>,
              <Button key="back" type="primary" 
                onClick={()=>{
  
                  if (this.state.reconciliation_status===""){
                    message.error("Please make achoice")
                  }else{
                    this.setState({datarequested:true})
                    const t_time="HH:mm:ss"
                    var total=Number(this.state.selected_record.order_total)+Number(this.state.selected_record.delivery_charges)
  
                    let form_data = new FormData();
                    form_data.append('order_id',this.state.selected_record.id);
                    form_data.append('paidAmount',total);
                    form_data.append('reconciliation_status',this.state.reconciliation_status);
  
                    form_data.append('dispatch_time', moment().format(t_time).toString());
                    axios.post(serverconfig.backendserverurl+'/customqueries/confirm_orderreconciliation', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      this.setState({datarequested:false})
                      //this.setState({datasubmittedsuccessfully:true})

                      message.info(res.data.message)
                      this.component_load()
                 
                    })
                    .catch(error => console.log(error))
  
                    this.setState({order_reconciliation_visible:false})
                  }
  
              }}>
                Submit 
              </Button>
  
              ]}
              >
  
             <FormItem label="Reconciled">
                    <Select 
                  placeholder="choice" 
                  value={this.state.reconciliation_status} 
                  onChange={(val)=>{
                    this.setState({reconciliation_status:val})
                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}    
                  >
                    <Option value={'yes'}> {'Yes'}</Option>
                    <Option value={'no'}> {'No'}</Option>
                  </Select>
  
             </FormItem>
         
          </Modal> 

          </div>
        );

      }

    }
  }
}

export default OrdersList;
