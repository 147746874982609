import React, { useState, useEffect, lazy } from "react";
import axios from "axios";
import { Slider, Statistic, Tabs, Modal,Switch } from "antd";
import { LoadingOutlined,SettingFilled } from "@ant-design/icons";
import moment from "moment";
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  Input,
  Collapse,
  Card,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  InputNumber,
  message,
  Spin,
} from "antd";
import { Col, Row } from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import * as serverconfig from "../serverconn";
import * as serversocketconfig from "../serversocketconfig";
import { FaFirstOrder } from "react-icons/fa";
import { MdMenu } from "react-icons/md";

import { FaFileInvoice } from "react-icons/fa";

import CurrencyFormat from "react-currency-format";
import Highlighter from "react-highlight-words";
import Websocket from "react-websocket";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NavLink } from "react-router-dom";
import * as primarycolor from "../primarycolor";

import RestaurantMenuCategoryList from './RestaurantMenuCategoryListView'
import { withGetScreen } from "react-getscreen";
import Orders_Tab from "./Orders_Tab";
import InvoiceList from "./InvoicesListView";

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = {
  background: "#0092ff",
  padding: "8px 0",
  borderRadius: "7px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const dateFormat = "DD/MM/YYYY";
const yeardateFormat = "YYYY";
const monthdateFormat = "MM/YYYY";

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;

var bizuserid = "";
var instance_id = "";
var token = "";

class Temporary_closureForm extends React.Component {
  state = {
    companyprofile: {},
    userprofile: {},
  };

  callback = (key) => {
    console.log(key);
    console.log(this.state.yearlymonthlysales);
  };

  componentDidMount() {
    this.component_loaded()
  }

  component_loaded=()=>{
    this.setState({ datarequested: true });

    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("bizuserid")) {
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      bizuserid = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/accounts/${bizuserid}`)
      .then((res) => {
        this.setState({
          userprofile: res.data,
        });

        this.setState({ datarequested: false });
      });

    axios
      .get(serverconfig.backendserverurl + `/api/restaurants/${instance_id}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });

        this.setState({ datarequested: false });

      });




  }



  render() {
    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      );
    } else {
      return (
        <div className="animated fadeIn">
          <FormItem  label="Closed Online" >
          <Switch
          checked={this.state.companyprofile.temporarily_closed_online}
          onChange={val=>{

            let form_data = new FormData();
            form_data.append('active',this.state.companyprofile.active);
            form_data.append('phoneorders_on',this.state.companyprofile.phoneorders_on);
            form_data.append('temporarily_closed_online',val);

            axios.put(serverconfig.backendserverurl+`/api/restaurants/${this.state.companyprofile.id}/`, form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res =>  {
            this.setState({datarequested:false})
            this.component_loaded()
            message.info("Updated")
        
          }   
        
          )
          .catch(error => console.log(error))
        
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
          />


          </FormItem>
          <Divider></Divider>

        </div>
      );
    }
  }
}

export default withGetScreen(Temporary_closureForm);
