import React from 'react';
import { Form, Input, Button,Spin,Image,Switch } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class RestaurantMenuCategoryDetail extends React.Component {

  state = {
    category_name:'' ,  
    description:'',
    datarequested:false,

    image:null,
    image_file:'',
    online:false

  }

  componentDidMount(){
    this.compo_loaded()

  }

  compo_loaded=()=>{

    this.setState({datarequested:true})
    var categoryID=this.props.categoryID

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    var categoryID=this.props.categoryID
    axios.get(`${serverconfig.backendserverurl}/api/restaurant_menu_categories/${categoryID}`)
    .then(res => {  
         this.setState({image_file:res.data.image})


        this.setState({category_name:res.data.category_name})
        this.setState({description:res.data.description})
        this.setState({online:res.data.online})

        this.setState({datarequested:false})
    })

  }




  //submit button pressed
  handleFormSubmit=(event) =>{
    var categoryID=this.props.categoryID
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('category_name', this.state.category_name);
    form_data.append('description', this.state.description);
    form_data.append('online', this.state.online);

    this.state.image==null?
    console.log("No image file")
    :
    form_data.append('image', this.state.image,this.state.image?this.state.image.name:"");

    axios.put(serverconfig.backendserverurl+`/api/restaurant_menu_categories/${categoryID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})

    this.compo_loaded()

    this.props.onrefresh()
    
     })
     
    .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Category Name"
       
      >
        <Input name="category_name" 
         placeholder="Put a name here."
         value={this.state.category_name} 
         onChange={(val)=>{this.setState({category_name:val.target.value})}} />
      </FormItem>


      <FormItem label="Description"
      >
        <Input name="description" 
         placeholder="Put a description here."
         value={this.state.description} 
         onChange={(val)=>{this.setState({description:val.target.value})}} />
      </FormItem>
          
      <div style={{display:'flex',flexDirection:'row'}}>
      <FormItem label="Image">
          <Input name="image" 
          type="file" 
          accept="image/png, image/jpeg"
          placeholder="Image" 
          
          onChange={(e) =>{
          if(e.target.files[0]){
            this.setState({
              image_file: URL.createObjectURL(e.target.files[0])
            })
          }
          this.setState({ image: e.target.files[0]})

          } }/>
      </FormItem> 

      {this.state.image!=null || this.state.image_file!=""?
        <Image
        width={50}
        height={50}
        src={this.state.image_file}    
        />
        :
        null
        }

      </div>


      <FormItem name="online" label="Online" >
      <Switch
          checked={this.state.online}
          onChange={val=>{this.setState({online:!this.state.online})}}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          />
      </FormItem>


      <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default RestaurantMenuCategoryDetail;



