import React from 'react';
import { Form, Input, Button,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class DishChoiceForm extends React.Component {

  state = {
    name:'' ,  
    price:'',
    datarequested:false,
  }

  componentDidMount(){
    this.comp_loaded()
  }


  comp_loaded=()=>{
    this.setState({datarequested:true})
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }
   

   this.setState({datarequested:false})


  }

  

  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    var dishID=this.props.dishID

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('dish', dishID);
      axios.post(serverconfig.backendserverurl+'/api/dish_choices/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
      this.setState({datarequested:false})
      this.comp_loaded()


      //refresh parent
      this.props.onrefresh()
      
    }    
    )
    .catch(error => console.log(error))

  }

  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name" 
         placeholder="Put a name here."
         value={this.state.name} 
         onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>

     
      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
      </FormItem>
       </Form>
        </div>
      );
    }

  }

}


export default DishChoiceForm;



