
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,Image  } from 'antd';
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined
} from '@ant-design/icons';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import axios from 'axios'
import logo from '../assets/eatslogo.png';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;

class LoginLayout extends React.Component{

  state = {
    userName:'' ,  
    password:'',
  }

   render(){
    return (
      <div>
         <Layout className="site-layout" >
         <Content className="site-layout" style={{ padding: '0 10px', marginTop: 0 }}>
           <div className="site-layout-background" style={{padding: 24, minHeight: 380,display: 'flex',  justifyContent:'center', alignItems:'center' }}  >
             {this.props.children}
           </div>
         </Content>
       <Footer style={{ textAlign: 'center',fontWeight:'bolder' }}>Copyright © 2024 eats.biz</Footer>
       </Layout>
      </div>
  );

  }

}

export default LoginLayout;

  
