import React from 'react';

const WhatsAppChat = ({ phoneNumber }) => {
  const handleChat = () => {
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  };

  return (
    <button onClick={handleChat} style={styles.button}>
      Chat with US On WhatsApp
    </button>
  );
};

const styles = {
  button: {
    backgroundColor: '#25D366',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default WhatsAppChat;
