import React from "react";
import axios from "axios";
import {
  Table,
  Input,
  Button,
  Collapse,
  Popover,
  Popconfirm,
  Form,
  message,
  Tabs,
  Spin,
  Modal,
  Select,
  Result,
  DatePicker,
  Tooltip,
  Card
} from "antd";
import Highlighter from "react-highlight-words";
import Icon from "@ant-design/icons";
import {
  SearchOutlined,
  DeleteOutlined,
  FundViewOutlined,
  DownloadOutlined,
  PrinterOutlined,
  LoadingOutlined,
  EditFilled,
  CloseCircleOutlined,
  PlayCircleOutlined,
  CheckCircleFilled,
  PlusCircleOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import * as serverconfig from "../serverconn";
import CurrencyFormat from "react-currency-format";
import * as reactstrp from "reactstrap";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { Col, Row } from "reactstrap";
import OrderUpdateForm from "./OrderUpdateForm";
import { MdMotorcycle } from "react-icons/md";
import * as primarycolor from '../primarycolor'
import '../../App.css'; // Create this file for custom styles

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem = Form.Item;
var token = "";
var instance_id=''
const { Search } = Input;
const { Option } = Select;
const { TextArea } = Input;

const rowClassName = (record) => {
  return record.order_type === "Online Order" ? 'highlighted-row' : '';
};


const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

class OnlineOrders extends React.Component {
  state = {
    searchText: "",
    searchedColumn: "",
    clientorders: [],
    datarequested: true,
    selected_record: {},
    accounts: [],
    selected_agent: "",

    dateone: moment().format(dateFormat).toString(),
    datetwo: moment().format(dateFormat).toString(),
    order_status: "All",


    order_reject_visible:false,
    cancelling_reason:'',
    selected_record:{},
    datasubmittedsuccessfully:false,
    
    order_assigned_rider:'',
    order_area:'',

    order_accept_visible:false,
    paidAmount:0,
    reconciliation_status:'',

    pending_online_orders:[]



  };

  componentDidMount(){

    this.component_load()
      
  }


   getYesterdayDate=()=> {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    const formattedYesterday = `${yesterday.getDate()}/${yesterday.getMonth() + 1}/${yesterday.getFullYear()}`;
    return formattedYesterday;
  }
  



  component_load=()=>{

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

  
    //get pending online orders
    axios
    .get(
      serverconfig.backendserverurl +`/api/orders/?restaurant=${instance_id}&order_status=${'Pending'}&order_type=${'Online Order'}`
    )
    .then((res) => {
      console.log("online orders: "+res.data)
      this.setState({pending_online_orders:res.data})

      this.setState({datarequested:false})
      });



    //get agents
    axios
      .get(serverconfig.backendserverurl + `/api/accounts/?is_online=${true}`)
      .then((res) => {
        this.setState({
          accounts: res.data,
        });
      });

      this.setState({datasubmittedsuccessfully:false})
  }




  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Order ID",
        dataIndex: "ordernumber",
        key: "id",
        ...this.getColumnSearchProps("ordernumber"),

        render: (text, record) => (
          <div>
             <p>
            {text}
            </p>

             {
              record.order_status === "Cancelled"?
              <p style={{fontWeight:'bold'}}>
              {"CANCELLED FOR: "+record.cancelling_reason}
              </p>
              :
              null
            }
 
          </div>
        )  

      },
      {
        title: "Order Type",
        dataIndex: "order_type",
        key: "id",
        ...this.getColumnSearchProps("order_type"),
      },
   
      {
        title: "Status",
        dataIndex: "order_status",
        key: "id",
        ...this.getColumnSearchProps("order_status"),
        render: (text, record) => (
          <div>
          <p
            style={{
              color:
                record.order_status === "pending"
                  ? "orange"
                  : record.order_status === "Cancelled"
                  ? "red"
                  : "green",
            }}
          >
            {text}
          </p>

          <p style={{display:'flex',flexDirection:'row',}}>

            {
             record.order_status==="Pending"?
             <Tooltip title="Click to reject order " placement="top">
               <CloseCircleOutlined onClick={()=>{
                  this.setState({selected_record:record})
                  this.setState({order_reject_visible:true})

               }}  style={{color:'#FF6347',fontSize: '30px',margin:2}} />
             </Tooltip>

              :
              null
            }


          { 
             record.order_status==="Pending"?
             <Tooltip title="Click to accept order " placement="top">
               <CheckCircleFilled onClick={()=>{
                  this.setState({selected_record:record})

                  let form_data = new FormData();
                  form_data.append('order_id',record.id);
    
                  axios.post(serverconfig.backendserverurl+'/customqueries/acceptorder_byrestaurant', form_data, {
                    headers: {
                      'content-type': 'multipart/form-data'
                    }
                    })
                  .then(res => {
                    this.setState({datarequested:false})
                    //this.setState({datasubmittedsuccessfully:true})
                     this.component_load() 

                     this.props.onRefresh()


                  })
                  .catch(error => console.log(error))


               }}  style={{color:'green',fontSize: '30px',margin:2}} />
             </Tooltip>
              :
              null
            }


          </p>

          </div>

        ),
      },
      {
        title: "Date (DD/MM/YYYY)",
        dataIndex: "date",
        key: "id",
        ...this.getColumnSearchProps("date"),
      },

   
      {
        title: "Delivery Area",
        dataIndex: "area",
        key: "id",
      },
      {
        title: "Delivery Fees ",
        dataIndex: "delivery_charges",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },

      {
        title: "Customer Name",
        dataIndex: "client_name",
        key: "id",
      },

      {
        title: "Customer Phone",
        dataIndex: "client_phone",
        key: "id",
      },

      {
        title: "Customer Email",
        dataIndex: "client_email",
        key: "id",
      },


      {
        title: "Total",
        dataIndex: "order_total",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },

      {
        title: "Total Paid",
        dataIndex: "total_paid",
        key: "id",
        render: (text) => (
          <CurrencyFormat
            value={text}
            displayType={"text"}
            thousandSeparator={true}
          />
        ),
      },
    ];

    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
          </Spin>
        </div>
      );
    } else {


      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title="Action successful"
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  this.component_load()
                }}>Finish</Button>,
              ]}
              />
              </Card>
          </div>
        )

      }else{

        return (
          <div>

              <Table
                columns={columns}
                scroll={{ x: 1000 }}
                pagination={{ showQuickJumper: true, showSizeChanger: true }}
                dataSource={this.state.pending_online_orders}
                bordered
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div
                        style={{
                          margin: 0,
                        }}
                      >
                      
                        <Tabs>

                          <TabPane tab="Items" key="01">

                            <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Item</th>
                                  <th>Quantity</th>
                                  <th>Amount</th>
                                  <th>Total</th>
                                  <th>Choices</th>
                                  <th>Extras</th>
                                  <th>Packaging fees</th>
                                </tr>
                              </thead>
                              <tbody>
                              {JSON.parse(record.item_details).map((item) => (
                                  <tr>
                                    <td>{item.key}</td>
                                    <td>{item.item}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.price}</td>
                                    <td>{item.total}</td>
                                    <th>
                                    {
                                      JSON.parse(item.choice_list).length>0?
                                      <span>
                                      <span>
                                      {
                                        JSON.parse(item.choice_list).map((x_itm)=>{
                                          return ""+(x_itm.item)+", "
                                        })       
                                      }
                                      </span>
                                      </span>
                                      :
                                      null
                                    }


                                    </th>
                                    <th>
                                    {
                                    JSON.parse(item.extra_list).length>0?
                                    <span>
                                    <span>
                                    {
                                      JSON.parse(item.extra_list).map((x_itm)=>{
                                        return "( "+(x_itm.item)+","+(x_itm.price)+" ), "
                                      })       
                                    }
                                    </span>
                                    </span>
                                    :
                                    null
                                  }

                                  </th>
                                  <th>{item.packaging_fees}</th>

                                  </tr>
                                ))}
                              </tbody>
                            </reactstrp.Table>

                          </TabPane>

                        </Tabs>
                      </div>
                    );
                  },
                  rowExpandable: (record) => true,
                  onExpand: (condition, record) => {},
                  expandIcon: (props) => {
                    const { expanded, onExpand } = props;
    
                      return (<span style={{ color: primarycolor.primarycolor, fontSize: '30px' }}
                      onClick={(e) => {
                      onExpand(props.record, e);
                    }}
                      
                      ><PlusCircleOutlined /></span>)
                    
                  }


                }}

                rowClassName={rowClassName}

              />



            <Modal   
            visible={this.state.order_reject_visible}
            title="Reject Order"
            onCancel={()=>{
              this.setState({order_reject_visible:false})
  
            }}
        
            footer={[
              <Button key="back" onClick={()=>{
                this.setState({order_reject_visible:false})
  
              }}>
                Cancel
              </Button>,
              <Button key="back" type="primary" 
                onClick={()=>{
  
                  if (this.state.cancelling_reason===""){
                    message.error("Please enter a reason")
  
                  }else{
                    this.setState({datarequested:true})
  
                    let form_data = new FormData();
                    form_data.append('order_id',this.state.selected_record.id);
                    form_data.append('cancelling_reason',this.state.cancelling_reason);
      
                    axios.post(serverconfig.backendserverurl+'/customqueries/rejectorder_byrestaurant', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      this.setState({datarequested:false})
                      //this.setState({datasubmittedsuccessfully:true})
                       this.component_load() 

                       this.props.onRefresh()

                    })
                    .catch(error => console.log(error))
              
                    this.setState({order_reject_visible:false})
                  }
  
              }}>
                Reject
              </Button>
  
              ]}
              >
  
          <FormItem label="Reason For Rejecting">
            
            <TextArea
                  placeholder="Reason ."
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  value={this.state.cancelling_reason}
                  onChange={(val)=>{this.setState({cancelling_reason:val.target.value})}}
                />

          </FormItem>
  
          </Modal> 


          </div>
        );

      }

    }
  }
}

export default OnlineOrders;
