import React, { useState, useEffect, lazy } from "react";
import axios from "axios";
import { Slider, Statistic, Tabs, Modal,Switch } from "antd";
import { LoadingOutlined,SettingFilled } from "@ant-design/icons";
import moment from "moment";
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  Input,
  Collapse,
  Card,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  InputNumber,
  message,
  Spin,
} from "antd";
import { Col, Row } from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import * as serverconfig from "../serverconn";
import * as serversocketconfig from "../serversocketconfig";
import { FaFirstOrder, FaWhatsapp, FaWhatsappSquare } from "react-icons/fa";
import { MdMenu, MdSettings } from "react-icons/md";

import { FaFileInvoice } from "react-icons/fa";

import CurrencyFormat from "react-currency-format";
import Highlighter from "react-highlight-words";
import Websocket from "react-websocket";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NavLink } from "react-router-dom";
import * as primarycolor from "../primarycolor";

import RestaurantMenuCategoryList from './RestaurantMenuCategoryListView'
import { withGetScreen } from "react-getscreen";
import Orders_Tab from "./Orders_Tab";
import InvoiceList from "./InvoicesListView";
import Temporary_closureForm from "./Temporary_closureForm";
import WhatsAppChat from './WhatsAppChat';

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = {
  background: "#0092ff",
  padding: "8px 0",
  borderRadius: "7px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const dateFormat = "DD/MM/YYYY";
const yeardateFormat = "YYYY";
const monthdateFormat = "MM/YYYY";

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;

var bizuserid = "";
var instance_id = "";
var token = "";

class MainBranch_Dashboard extends React.Component {
  state = {
    companyprofile: {},
    userprofile: {},
    current_key:'001',
    app_profile:{}
  };

  callback = (key) => {
    console.log(key);
    console.log(this.state.yearlymonthlysales);
  };

  componentDidMount() {
    this.component_loaded()
  }

  component_loaded=()=>{
    this.setState({ datarequested: true });

    if (localStorage.getItem("instance_id")) {
      instance_id = localStorage.getItem("instance_id");
    } else {
      instance_id = "";
    }

    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      token = "";
    }

    if (localStorage.getItem("bizuserid")) {
      bizuserid = CryptoJS.AES.decrypt(
        localStorage.getItem("bizuserid"),
        "my-secret-key@1234"
      ).toString(CryptoJS.enc.Utf8);
    } else {
      bizuserid = "";
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios
      .get(serverconfig.backendserverurl + `/api/accounts/${bizuserid}`)
      .then((res) => {
        this.setState({
          userprofile: res.data,
        });

        this.setState({ datarequested: false });
      });

    axios
      .get(serverconfig.backendserverurl + `/api/restaurants/${instance_id}`)
      .then((res) => {
        this.setState({
          companyprofile: res.data,
        });

        this.setState({ datarequested: false });

      });


    axios
    .get(serverconfig.backendserverurl + `/api/app_profile/${1}`)
    .then((res) => {
      this.setState({
        app_profile: res.data,
      });
    });

  }



  render() {
    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      );
    } else {
      return (
        <div className="animated fadeIn">
          <h3
            style={{
              fontStyle: "oblique",
              fontWeight: "bolder",
              color: primarycolor.primarycolor,
            }}
          >
            {this.state.companyprofile.name}
          </h3>

          <Tabs
            defaultActiveKey="001"
            onChange={(val) => {
              this.setState({current_key:val})
            }}
            tabPosition={this.props.isMobile() ? "bottom" : "top"}
          >


            <TabPane 
              tab={
                <span>
                  <FaFirstOrder />
                  &nbsp;
                 Orders
                </span>
              }
               key="001">

              {
                this.state.current_key==="001"?
                <Orders_Tab />
                :
                null

              }
            </TabPane>

            <TabPane 
            
            tab={
              <span>
                <MdMenu />
                &nbsp;
                Menus
              </span>
            }
            key="001121">

            {
              this.state.current_key==="001121"?
            <RestaurantMenuCategoryList restaurantID={instance_id}/>
            :null}

            </TabPane>

            <TabPane  key="00001123"
               tab={
                <span>
                  <FaFileInvoice />
                  &nbsp;
                  Invoices
                </span>
              }
            >
                            {
                this.state.current_key==="00001123"?

            <InvoiceList/>:null}
            </TabPane>

            <TabPane  key="0009901123"
               tab={
                <span>
                  <MdSettings/>
                  &nbsp;
                  Settings
                </span>
              }
            >                        {
                this.state.current_key==="0009901123"?

              <Temporary_closureForm />:null}

            </TabPane>


               {
                  this.state.app_profile.support_whatsappno===null || this.state.app_profile.support_whatsappno===''||this.state.app_profile.support_whatsappno==='null'?
                  null
                  :
                <TabPane  key="0001119901123"
                  tab={
                    <span>
                      <FaWhatsapp />
                      &nbsp;
                      Chat
                    </span>
                  } >
                {
                this.state.current_key==="0001119901123"?

                <WhatsAppChat phoneNumber={this.state.app_profile.support_whatsappno} />
              
              :null}

            </TabPane>

             }                            
            

          </Tabs>
        </div>
      );
    }
  }
}

export default withGetScreen(MainBranch_Dashboard);
