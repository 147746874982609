import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
//import 'antd/dist/antd.css'; 
import 'antd/dist/result.css';

import { connect } from 'react-redux'
import * as actions from './store/actions/auth'
import { BrowserRouter as Router } from 'react-router-dom'

import LoginLayout from './views/LoginLayout'
import Login from './views/Login';
import SaccoLayoutCollection from './views/containers/SaccoslayoutCollectionBranch'

import SaccoBaseRouter from './saccoroutes'
import { Slider, Statistic, Tabs,Result, Modal,Button,Steps, List, Affix  } from "antd";

// Containers

const App = (props) => {


  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);


  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsModalVisible(false);
      });
    }
  };


  useEffect(() => {
    props.onTryAutoSignup();


    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsModalVisible(true);
    };



    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };

  }, []);
  

    return (
      <div>


        {
          props.isAuthenticated ?
          <Router>
          <SaccoLayoutCollection {...props}>
            <SaccoBaseRouter/>
          </SaccoLayoutCollection>
          </Router>
             
          :
          <Router>
          <LoginLayout>
            <Login />
          </LoginLayout>
          </Router>
        }


      <Modal
        title="Install partner.eats.biz App"
        visible={isModalVisible}
        onOk={handleInstallClick}
        onCancel={()=>{
          setIsModalVisible(false);

        }}
        okText="Install"
        cancelText="Cancel"
      >
        <p>Install our app to enjoy the full experience and more features!</p>
      </Modal>



      </div>


    );
  
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
